export const collections = {
  "mathematics": {
    "name": "mathematics",
    "pascalName": "Mathematics",
    "tableName": "_content_mathematics",
    "source": [
      {
        "_resolved": true,
        "prefix": "/mathematics",
        "include": "mathematics/**/*",
        "cwd": "/vercel/path0/content"
      }
    ],
    "type": "data",
    "fields": {
      "id": "string",
      "stem": "string",
      "extension": "string",
      "meta": "json",
      "game_reference": "json",
      "game_id": "string",
      "game_name": "string",
      "game_description": "string",
      "unlocked": "string",
      "game_properties": "json",
      "game_levels": "json",
      "core_competences": "json"
    },
    "schema": {
      "$ref": "#/definitions/mathematics",
      "definitions": {
        "mathematics": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "stem": {
              "type": "string"
            },
            "extension": {
              "type": "string",
              "enum": [
                "md",
                "yaml",
                "yml",
                "json",
                "csv",
                "xml"
              ]
            },
            "meta": {
              "type": "object",
              "additionalProperties": {}
            },
            "game_reference": {
              "type": "object",
              "properties": {
                "grade": {
                  "type": "string",
                  "minLength": 1
                },
                "term": {
                  "type": "string",
                  "minLength": 1
                },
                "subject": {
                  "type": "string",
                  "minLength": 1
                },
                "strand": {
                  "type": "string",
                  "minLength": 1
                },
                "substrand": {
                  "type": "string",
                  "minLength": 1
                },
                "content_standard": {
                  "type": "string",
                  "minLength": 1
                },
                "indicator": {
                  "type": "string",
                  "minLength": 1
                }
              },
              "required": [
                "grade",
                "term",
                "subject",
                "strand",
                "substrand",
                "content_standard",
                "indicator"
              ],
              "additionalProperties": false
            },
            "game_id": {
              "type": "string",
              "minLength": 1
            },
            "game_name": {
              "type": "string",
              "minLength": 1
            },
            "game_description": {
              "type": "string",
              "minLength": 1
            },
            "unlocked": {
              "type": "string",
              "minLength": 1
            },
            "game_properties": {
              "type": "object",
              "properties": {
                "game_type": {
                  "type": "string",
                  "minLength": 1
                },
                "operators": {
                  "type": "array",
                  "items": {
                    "type": "string",
                    "minLength": 1
                  }
                },
                "unique_shapes": {
                  "type": "boolean"
                },
                "question_types": {
                  "type": "array",
                  "items": {
                    "type": "string",
                    "minLength": 1
                  }
                },
                "visual_type": {
                  "type": "string"
                },
                "useOperatorName": {
                  "type": "boolean"
                }
              },
              "required": [
                "game_type",
                "operators"
              ],
              "additionalProperties": false
            },
            "game_levels": {
              "type": "array",
              "items": {
                "type": "object",
                "properties": {
                  "level": {
                    "type": "number"
                  },
                  "expressions_per_question": {
                    "type": "number"
                  },
                  "unique_shapes": {
                    "type": "boolean"
                  },
                  "shapes": {
                    "type": "array",
                    "items": {
                      "type": "string",
                      "minLength": 1
                    }
                  },
                  "groupSize": {
                    "type": "number"
                  },
                  "range": {
                    "type": "object",
                    "properties": {
                      "min": {
                        "type": "number"
                      },
                      "max": {
                        "type": "number"
                      }
                    },
                    "required": [
                      "min",
                      "max"
                    ],
                    "additionalProperties": false
                  }
                },
                "required": [
                  "level",
                  "range"
                ],
                "additionalProperties": false
              }
            },
            "core_competences": {
              "type": "array",
              "items": {
                "type": "string",
                "minLength": 1
              }
            }
          },
          "required": [
            "id",
            "stem",
            "extension",
            "meta",
            "game_reference",
            "game_id",
            "game_name",
            "game_description",
            "unlocked",
            "game_properties",
            "game_levels",
            "core_competences"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_mathematics (id TEXT PRIMARY KEY, \"core_competences\" TEXT, \"extension\" VARCHAR, \"game_description\" VARCHAR, \"game_id\" VARCHAR, \"game_levels\" TEXT, \"game_name\" VARCHAR, \"game_properties\" TEXT, \"game_reference\" TEXT, \"meta\" TEXT, \"stem\" VARCHAR, \"unlocked\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  },
  "info": {
    "name": "info",
    "pascalName": "Info",
    "tableName": "_content_info",
    "type": "data",
    "fields": {},
    "schema": {
      "$ref": "#/definitions/info",
      "definitions": {
        "info": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "version": {
              "type": "string"
            },
            "structureVersion": {
              "type": "string"
            },
            "ready": {
              "type": "boolean"
            }
          },
          "required": [
            "id",
            "version",
            "structureVersion",
            "ready"
          ],
          "additionalProperties": false
        }
      },
      "$schema": "http://json-schema.org/draft-07/schema#"
    },
    "tableDefinition": "CREATE TABLE IF NOT EXISTS _content_info (id TEXT PRIMARY KEY, \"ready\" BOOLEAN, \"structureVersion\" VARCHAR, \"version\" VARCHAR, \"__hash__\" TEXT UNIQUE);"
  }
}
export const gitInfo = {
  "name": "wlearn-academy-ghana",
  "owner": "keoma",
  "url": "https://github.com/keoma/wlearn-academy-ghana"
}
export const appConfigSchema = {
  "properties": {
    "id": "#appConfig",
    "properties": {
      "icon": {
        "title": "Nuxt Icon",
        "description": "Configure Nuxt Icon module preferences.",
        "tags": [
          "@studioIcon material-symbols:star"
        ],
        "id": "#appConfig/icon",
        "properties": {
          "size": {
            "title": "Icon Size",
            "description": "Set the default icon size.",
            "tags": [
              "@studioIcon material-symbols:format-size-rounded"
            ],
            "tsType": "string | undefined",
            "id": "#appConfig/icon/size",
            "default": {},
            "type": "any"
          },
          "class": {
            "title": "CSS Class",
            "description": "Set the default CSS class.",
            "tags": [
              "@studioIcon material-symbols:css"
            ],
            "id": "#appConfig/icon/class",
            "default": "",
            "type": "string"
          },
          "attrs": {
            "title": "Default Attributes",
            "description": "Attributes applied to every icon component.\n\n@default { \"aria-hidden\": true }",
            "tags": [
              "@studioIcon material-symbols:settings"
            ],
            "tsType": "Record<string, string | number | boolean>",
            "id": "#appConfig/icon/attrs",
            "default": {
              "aria-hidden": true
            },
            "type": "object"
          },
          "mode": {
            "title": "Default Rendering Mode",
            "description": "Set the default rendering mode for the icon component",
            "enum": [
              "css",
              "svg"
            ],
            "tags": [
              "@studioIcon material-symbols:move-down-rounded"
            ],
            "id": "#appConfig/icon/mode",
            "default": "css",
            "type": "string"
          },
          "aliases": {
            "title": "Icon aliases",
            "description": "Define Icon aliases to update them easily without code changes.",
            "tags": [
              "@studioIcon material-symbols:star-rounded"
            ],
            "tsType": "{ [alias: string]: string }",
            "id": "#appConfig/icon/aliases",
            "default": {},
            "type": "object"
          },
          "cssSelectorPrefix": {
            "title": "CSS Selector Prefix",
            "description": "Set the default CSS selector prefix.",
            "tags": [
              "@studioIcon material-symbols:format-textdirection-l-to-r"
            ],
            "id": "#appConfig/icon/cssSelectorPrefix",
            "default": "i-",
            "type": "string"
          },
          "cssLayer": {
            "title": "CSS Layer Name",
            "description": "Set the default CSS `@layer` name.",
            "tags": [
              "@studioIcon material-symbols:layers"
            ],
            "tsType": "string | undefined",
            "id": "#appConfig/icon/cssLayer",
            "default": {},
            "type": "any"
          },
          "cssWherePseudo": {
            "title": "Use CSS `:where()` Pseudo Selector",
            "description": "Use CSS `:where()` pseudo selector to reduce specificity.",
            "tags": [
              "@studioIcon material-symbols:low-priority"
            ],
            "id": "#appConfig/icon/cssWherePseudo",
            "default": true,
            "type": "boolean"
          },
          "collections": {
            "title": "Icon Collections",
            "description": "List of known icon collections name. Used to resolve collection name ambiguity.\ne.g. `simple-icons-github` -> `simple-icons:github` instead of `simple:icons-github`\n\nWhen not provided, will use the full Iconify collection list.",
            "tags": [
              "@studioIcon material-symbols:format-list-bulleted"
            ],
            "tsType": "string[] | null",
            "id": "#appConfig/icon/collections",
            "default": null,
            "type": "any"
          },
          "customCollections": {
            "title": "Custom Icon Collections",
            "tags": [
              "@studioIcon material-symbols:format-list-bulleted"
            ],
            "tsType": "string[] | null",
            "id": "#appConfig/icon/customCollections",
            "default": null,
            "type": "any"
          },
          "provider": {
            "title": "Icon Provider",
            "description": "Provider to use for fetching icons\n\n- `server` - Fetch icons with a server handler\n- `iconify` - Fetch icons with Iconify API, purely client-side\n\n`server` by default; `iconify` when `ssr: false`",
            "enum": [
              "server",
              "iconify"
            ],
            "tags": [
              "@studioIcon material-symbols:cloud"
            ],
            "type": "\"server\" | \"iconify\" | undefined",
            "id": "#appConfig/icon/provider"
          },
          "iconifyApiEndpoint": {
            "title": "Iconify API Endpoint URL",
            "description": "Define a custom Iconify API endpoint URL. Useful if you want to use a self-hosted Iconify API. Learn more: https://iconify.design/docs/api.",
            "tags": [
              "@studioIcon material-symbols:api"
            ],
            "id": "#appConfig/icon/iconifyApiEndpoint",
            "default": "https://api.iconify.design",
            "type": "string"
          },
          "fallbackToApi": {
            "title": "Fallback to Iconify API",
            "description": "Fallback to Iconify API if server provider fails to found the collection.",
            "tags": [
              "@studioIcon material-symbols:public"
            ],
            "enum": [
              true,
              false,
              "server-only",
              "client-only"
            ],
            "type": "boolean | \"server-only\" | \"client-only\"",
            "id": "#appConfig/icon/fallbackToApi",
            "default": true
          },
          "localApiEndpoint": {
            "title": "Local API Endpoint Path",
            "description": "Define a custom path for the local API endpoint.",
            "tags": [
              "@studioIcon material-symbols:api"
            ],
            "id": "#appConfig/icon/localApiEndpoint",
            "default": "/api/_nuxt_icon",
            "type": "string"
          },
          "fetchTimeout": {
            "title": "Fetch Timeout",
            "description": "Set the timeout for fetching icons.",
            "tags": [
              "@studioIcon material-symbols:timer"
            ],
            "id": "#appConfig/icon/fetchTimeout",
            "default": 1500,
            "type": "number"
          },
          "customize": {
            "title": "Customize callback",
            "description": "Customize icon content (replace stroke-width, colors, etc...).",
            "tags": [
              "@studioIcon material-symbols:edit"
            ],
            "type": "IconifyIconCustomizeCallback",
            "id": "#appConfig/icon/customize"
          }
        },
        "type": "object",
        "default": {
          "size": {},
          "class": "",
          "attrs": {
            "aria-hidden": true
          },
          "mode": "css",
          "aliases": {},
          "cssSelectorPrefix": "i-",
          "cssLayer": {},
          "cssWherePseudo": true,
          "collections": null,
          "customCollections": null,
          "iconifyApiEndpoint": "https://api.iconify.design",
          "fallbackToApi": true,
          "localApiEndpoint": "/api/_nuxt_icon",
          "fetchTimeout": 1500
        }
      },
      "ui": {
        "title": "UI",
        "description": "UI Customization.",
        "tags": [
          "@previewIcon i-mdi-palette-outline"
        ],
        "id": "#appConfig/ui",
        "properties": {
          "icons": {
            "title": "Icons",
            "description": "Manage icons used in UI Pro.",
            "tags": [
              "@previewIcon i-mdi-application-settings-outline"
            ],
            "id": "#appConfig/ui/icons",
            "properties": {
              "search": {
                "type": "string",
                "title": "Search Bar",
                "description": "Icon to display in the search bar.",
                "default": "i-lucide-search",
                "tags": [
                  "@previewInput icon",
                  "@previewIcon i-mdi-magnify"
                ],
                "id": "#appConfig/ui/icons/search"
              },
              "dark": {
                "type": "string",
                "title": "Dark mode",
                "description": "Icon of color mode button for dark mode.",
                "default": "i-lucide-moon",
                "tags": [
                  "@previewInput icon",
                  "@previewIcon i-mdi-moon-waning-crescent"
                ],
                "id": "#appConfig/ui/icons/dark"
              },
              "light": {
                "type": "string",
                "title": "Light mode",
                "description": "Icon of color mode button for light mode.",
                "default": "i-lucide-sun",
                "tags": [
                  "@previewInput icon",
                  "@previewIcon i-mdi-white-balance-sunny"
                ],
                "id": "#appConfig/ui/icons/light"
              },
              "external": {
                "type": "string",
                "title": "External Link",
                "description": "Icon for external link.",
                "default": "i-lucide-external-link",
                "tags": [
                  "@previewInput icon",
                  "@previewIcon i-mdi-arrow-top-right"
                ],
                "id": "#appConfig/ui/icons/external"
              },
              "chevron": {
                "type": "string",
                "title": "Chevron",
                "description": "Icon for chevron.",
                "default": "i-lucide-chevron-down",
                "tags": [
                  "@previewInput icon",
                  "@previewIcon i-mdi-chevron-down"
                ],
                "id": "#appConfig/ui/icons/chevron"
              },
              "hash": {
                "type": "string",
                "title": "Hash",
                "description": "Icon for hash anchors.",
                "default": "i-lucide-hash",
                "tags": [
                  "@previewInput icon",
                  "@previewIcon i-ph-hash"
                ],
                "id": "#appConfig/ui/icons/hash"
              }
            },
            "type": "object",
            "default": {
              "search": "i-lucide-search",
              "dark": "i-lucide-moon",
              "light": "i-lucide-sun",
              "external": "i-lucide-external-link",
              "chevron": "i-lucide-chevron-down",
              "hash": "i-lucide-hash"
            }
          },
          "colors": {
            "primary": {
              "$schema": {
                "type": "string",
                "title": "Primary",
                "description": "Primary color of your UI.",
                "default": "green",
                "required": [
                  "red",
                  "orange",
                  "amber",
                  "yellow",
                  "lime",
                  "green",
                  "emerald",
                  "teal",
                  "cyan",
                  "sky",
                  "blue",
                  "indigo",
                  "violet",
                  "purple",
                  "fuchsia",
                  "pink",
                  "rose"
                ],
                "tags": [
                  "@previewInput string",
                  "@previewIcon i-mdi-palette-outline"
                ]
              }
            },
            "neutral": {
              "$schema": {
                "type": "string",
                "title": "Neutral",
                "description": "Neutral color of your UI.",
                "default": "slate",
                "required": [
                  "slate",
                  "gray",
                  "zinc",
                  "neutral",
                  "stone"
                ],
                "tags": [
                  "@previewInput string",
                  "@previewIcon i-mdi-palette-outline"
                ]
              }
            },
            "id": "#appConfig/ui/colors",
            "type": "any",
            "default": {}
          }
        },
        "type": "object",
        "default": {
          "icons": {
            "search": "i-lucide-search",
            "dark": "i-lucide-moon",
            "light": "i-lucide-sun",
            "external": "i-lucide-external-link",
            "chevron": "i-lucide-chevron-down",
            "hash": "i-lucide-hash"
          },
          "colors": {}
        }
      }
    },
    "type": "object",
    "default": {
      "icon": {
        "size": {},
        "class": "",
        "attrs": {
          "aria-hidden": true
        },
        "mode": "css",
        "aliases": {},
        "cssSelectorPrefix": "i-",
        "cssLayer": {},
        "cssWherePseudo": true,
        "collections": null,
        "customCollections": null,
        "iconifyApiEndpoint": "https://api.iconify.design",
        "fallbackToApi": true,
        "localApiEndpoint": "/api/_nuxt_icon",
        "fetchTimeout": 1500
      },
      "ui": {
        "icons": {
          "search": "i-lucide-search",
          "dark": "i-lucide-moon",
          "light": "i-lucide-sun",
          "external": "i-lucide-external-link",
          "chevron": "i-lucide-chevron-down",
          "hash": "i-lucide-hash"
        },
        "colors": {}
      }
    }
  },
  "default": {
    "icon": {
      "size": {},
      "class": "",
      "attrs": {
        "aria-hidden": true
      },
      "mode": "css",
      "aliases": {},
      "cssSelectorPrefix": "i-",
      "cssLayer": {},
      "cssWherePseudo": true,
      "collections": null,
      "customCollections": null,
      "iconifyApiEndpoint": "https://api.iconify.design",
      "fallbackToApi": true,
      "localApiEndpoint": "/api/_nuxt_icon",
      "fetchTimeout": 1500
    },
    "ui": {
      "icons": {
        "search": "i-lucide-search",
        "dark": "i-lucide-moon",
        "light": "i-lucide-sun",
        "external": "i-lucide-external-link",
        "chevron": "i-lucide-chevron-down",
        "hash": "i-lucide-hash"
      },
      "colors": {}
    }
  }
}